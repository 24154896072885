import React from "react";
import imageSrc from "../../images/divinefetus.png";
import "./App.css";

function App() {
	return (
		<div className="outer-box">
			<div className="inner-box">
				<span className="tr"></span>
				<span className="tl"></span>
				<span className="br"></span>
				<span className="bl"></span>
				<div className="topFeature">
					<h1>Church of Divine Fetus</h1>
					<img src={imageSrc} className="mainGraphic" alt="The Divine Fetus" />
					<div className="text-container">
						<div className="hero-content">
							<h2>Welcome Traveler</h2>
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. Et
								netus et malesuada fames ac turpis egestas maecenas. Iaculis eu
								non diam phasellus vestibulum lorem. Quis risus sed vulputate
								odio ut enim blandit. Egestas maecenas pharetra convallis
								posuere morbi leo urna molestie at. Eget magna fermentum iaculis
								eu. Bibendum est ultricies integer quis. Id nibh tortor id
								aliquet lectus proin nibh. Dui vivamus arcu felis bibendum ut
								tristique et egestas. Sed adipiscing diam donec adipiscing
								tristique risus. Egestas quis ipsum suspendisse ultrices gravida
								dictum fusce ut placerat.
							</p>
							<div className="button-container">
								<div>Div 1</div>
								<div>Div 2</div>
								<div>Div 3</div>
							</div>
						</div>
					</div>
				</div>
				<div className="bottomFeature">
					<div className="box one">
						<div className="child">
							<div className="content">
								<h3>Conception</h3>
							</div>
						</div>
						<div className="child">
							<div className="content">
								Divinity begins at conception. This is a fundamental cornerstone
								to our beliefs. Once known, you will see the hidden truths of
								our existence, and the great question we all must answer.
							</div>
						</div>
						<div className="child">
							<div className="content">
								The Prophet and Doctor Rachel Davis discuss their discoveries,
								and their road to The Divine.
							</div>
						</div>
					</div>
					<div className="box two">
						<div className="child">
							<div className="content">
								<h3>Birth</h3>
							</div>
						</div>
						<div className="child">
							<div className="content">
								A catastrophe: From womb to tomb. One's entrance from the Cosmic
								Gateway and into humanity is the greatest tragedy. From this, we
								can be conceived anew with the 9 eras.
							</div>
						</div>
						<div className="child">
							<div className="content">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua.
							</div>
						</div>
					</div>
					<div className="box three">
						<div className="child">
							<div className="content">
								<h3>Journey</h3>
							</div>
						</div>
						<div className="child">
							<div className="content">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua.
							</div>
						</div>
						<div className="child">
							<div className="content">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua.
							</div>
						</div>
					</div>
					<div className="box four">
						<div className="child">
							<div className="content">
								<h3>Renewal</h3>
							</div>
						</div>
						<div className="child">
							<div className="content">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua.
							</div>
						</div>
						<div className="child">
							<div className="content">
								Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
